import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  unitFactors,
  unitCodeFromLocalizedUnit,
  unitCodeFromUnit,
  getGramsPerCustomUnit,
  getGramsPerCustomUnitForm
} from '@src/utils/helpers/units';
import Input from '@comp/Forms/Input';
import s from './helpers.scss';

const cx = classNames.bind(s);

const CUSTOM_UNIT = 'CUSTOM';

export const TextInput = ({
  fieldName,
  value,
  onChange,
  className,
  inputClassName,
  inputLabelClassName,
  label,
  errors,
  placeholder,
  small,
  input
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-for
  <label
    className={cx({
      [s.inputLabel]: true,
      [className]: className,
      [s.inputSmall]: small,
      [inputLabelClassName]: inputLabelClassName
    })}
    htmlFor={fieldName}
  >
    {label}
    <Input
      inputClassName={`${inputClassName} ${s.inputClassName}`}
      input={{
        id: fieldName,
        value: value || '',
        onChange,
        placeholder,
        ...input
      }}
    />
    {errors && errorOutput(errors)}
  </label>
);

TextInput.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  inputLabelClassName: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  errors: PropTypes.array,
  small: PropTypes.bool,
  placeholder: PropTypes.string
};

export const NumberInput = ({
  fieldName,
  value,
  onChange,
  className,
  inputClassName,
  label,
  errors,
  placeholder,
  max,
  small
}) => (
  // eslint-disable-next-line jsx-a11y/label-has-for
  <label
    className={cx({
      [s.inputLabel]: true,
      [className]: className,
      [s.inputSmall]: small
    })}
    htmlFor={fieldName}
  >
    <div>{label}</div>
    <Input
      inputClassName={`${inputClassName} ${s.inputClassName}`}
      input={{
        id: fieldName,
        value: value !== null ? value : '',
        onChange,
        type: 'number',
        placeholder,
        max
      }}
    />
    {errors && errorOutput(errors)}
  </label>
);

NumberInput.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.array,
  small: PropTypes.bool,
  max: PropTypes.string,
  placeholder: PropTypes.string
};

export const getCustomUnitName = ({ unitName }, customUnits) => {
  const isCustomUnit = customUnits.find(({ name }) => name === unitName);
  return isCustomUnit
    ? {
        unitName: `${CUSTOM_UNIT}-${customUnits.findIndex(
          ({ name }) => name === unitName
        )}`,
        isCustomUnit
      }
    : { unitName };
};

export const getProperUnit = ({ unitName = null, units }, isWellobe) => {
  const gramsPerUnit = getGramsPerCustomUnit(units, unitName);
  const simpleUnitCode = isWellobe
    ? unitCodeFromLocalizedUnit(unitName)
    : unitCodeFromUnit(unitName);
  return {
    unitName,
    gramsPerUnit: simpleUnitCode ? null : gramsPerUnit
  };
};

export const getJsonUnit = (
  {
    unitName: newUnitName,
    customUnits: newCustomUnits,
    gramsPerMilliliter
  } = {},
  defaultUnitName,
  defaultCustomUnits,
  isAdmin
) => {
  const unitName = newUnitName || defaultUnitName;
  const customUnits = newCustomUnits || defaultCustomUnits;
  const isCustomUnit = unitName?.includes(CUSTOM_UNIT) && customUnits;
  return {
    ...(unitName?.includes(CUSTOM_UNIT) && customUnits
      ? { unitName: customUnits[unitName.split('-')[1]]?.name }
      : {
          unitName
        }),
    ...{
      gramsPerUnit: isCustomUnit
        ? getGramsPerCustomUnitForm(customUnits, unitName)
        : null
    },
    ...(gramsPerMilliliter && {
      gramsPerMilliliter: isAdmin
        ? gramsPerMilliliter
        : gramsPerMilliliter / (unitFactors[unitName] || 1)
    })
  };
};

export const errorOutput = (errors, className) =>
  errors.map(({ key, message }) => (
    <div key={key} className={`${s.errors} ${className}`}>
      {message}
    </div>
  ));

export const texts = {
  systemFoodLabel: 'Visible to all users',
  systemFoodInfo:
    'This is system food so remember that if you change the calorie intake or nutrients, ' +
    "it will affect user's calorie progress charts for the day they logged the food. ",
  deleteSystemFoodInfo:
    'If you make the item invisible it will be still visible in the logbook. ' +
    'You cannot reverse this operation (without backenders help)',
  descriptivePackageSizeLabel: 'Descriptive package size',
  addCustomUnit: 'Add custom unit',
  chooseDefaultUnit: 'Choose default unit',
  defaultUnit: 'Default unit',
  suitableForRecipesLabel: 'Suitable for recipes',
  gtinLabel: 'Barcode',
  createdLabel: 'Created on ',
  updatedLabel: 'Updated on',
  idLabel: 'Id:',
  visibilityLabel: 'Visibility and food type',
  systemSettingsLabel: 'System settings',
  kcalPer100g: 'Kcal per 100g',
  macronutrientsLabel: 'Macronutrients per 100g',
  basicInfoLabel: 'About foodstuff',
  gramSuffix: '(g)',
  dataSourceLabel: 'Data source'
};

export const VISIBILITY_STATUS = {
  VISIBLE: 'VISIBLE',
  DELETED: 'DELETED',
  HIDDEN: 'HIDDEN'
};

export const FOOD_TYPE = {
  SYSTEM: 'SYSTEM_FOODSTUFF',
  USER: 'USER_FOODSTUFF'
};

export default {
  texts
};
