import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import {
  units,
  localizedUnit,
  unitCodeFromLocalizedUnit
} from '@src/utils/helpers/units';
import NutrientFieldSet from '../NutrientFieldSet';
import UnitFieldSet from '../UnitFieldSet';
import BasicInfoFieldSet from '../BasicInfoFieldSet';
import SystemSettingsFieldSet from '../SystemSettingsFieldSet';
import s from './AdminFoodstuffFormFieldSets.scss';

import { VISIBILITY_STATUS, FOOD_TYPE } from '../helpers';

class AdminFoodstuffFormFieldSets extends React.Component {
  defaultFormData = {
    name: null,
    brandName: null,
    kcalPer100g: null,
    unitName: localizedUnit(units.GRAM),
    isCustomUnit: false,
    gramsPerUnit: null,
    gramsPerMilliliter: null,
    descriptivePackageSize: null,
    foodType: FOOD_TYPE.USER,
    visibilityStatus: VISIBILITY_STATUS.VISIBLE,
    gtin: null,
    suitableForRecipes: false,
    createdDate: null,
    updatedDate: null,
    customUnits: []
  };

  constructor(props) {
    super(props);
    const { initialTitle } = props;
    this.state = {
      changedData: initialTitle ? { name: initialTitle } : {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState, this.state)) {
      const {
        data: initialData,
        onChange,
        displayGramsPerMilliliter
      } = this.props;
      const { defaultFormData } = this;
      if (onChange)
        onChange({
          ...defaultFormData,
          ...(displayGramsPerMilliliter ? { unitName: '' } : {}),
          ...initialData,
          ...this.state.changedData
        });
    }
  }

  availabilityProperties = (initialFoodType, checked) => {
    if (initialFoodType === FOOD_TYPE.SYSTEM) {
      return {
        visibilityStatus: checked
          ? VISIBILITY_STATUS.VISIBLE
          : VISIBILITY_STATUS.DELETED,
        foodType: FOOD_TYPE.SYSTEM
      };
    }
    // For USER type or null when creating a new foodstuff
    return {
      visibilityStatus: VISIBILITY_STATUS.VISIBLE,
      foodType: checked ? FOOD_TYPE.SYSTEM : FOOD_TYPE.USER
    };
  };

  publiclyAvailabilityChanged = event => {
    const { checked } = event.target;
    const initialFoodType = get(this.props, 'data.foodType');

    this.setState(({ changedData }) => ({
      changedData: {
        ...changedData,
        ...this.availabilityProperties(initialFoodType, checked)
      }
    }));
  };

  addCustomUnit = () => {
    const updatedFoodstuff = {
      ...this.defaultFormData,
      ...this.props.data,
      ...this.state.changedData
    };
    const { customUnits } = updatedFoodstuff;
    this.setState(prevState => ({
      changedData: {
        ...prevState.changedData,
        customUnits: [...customUnits, { name: '', gramsPerUnit: '' }]
      }
    }));
  };

  removeCustomUnit = customUnitIndex => {
    const updatedFoodstuff = {
      ...this.defaultFormData,
      ...this.props.data,
      ...this.state.changedData
    };
    const { customUnits } = updatedFoodstuff;
    this.setState(prevState => ({
      changedData: {
        ...prevState.changedData,
        customUnits: [
          ...customUnits.slice(0, customUnitIndex),
          ...customUnits.slice(customUnitIndex + 1)
        ]
      }
    }));
  };

  handleFieldChanged = event => {
    const { id: name, value, type, checked } = event.target;
    const updatedFoodstuff = {
      ...this.defaultFormData,
      ...this.props.data,
      ...this.state.changedData
    };
    const { isAdmin } = this.props;
    const { customUnits } = updatedFoodstuff;
    const newData = {};
    const newCustomUnits = customUnits || [];
    if (name.includes('gramsPerUnit-')) {
      const customUnitIndex = name.split('-')[1];
      newCustomUnits[customUnitIndex].gramsPerUnit = value;
    } else if (type === 'number') {
      let floatValue = parseFloat(value);
      if (isNaN(floatValue)) {
        floatValue = null;
      }
      newData[name] = floatValue;
    } else if (type === 'checkbox') {
      newData[name] = checked;
    } else if (name === 'unit') {
      const newUnitCode = unitCodeFromLocalizedUnit(localizedUnit(value));
      if (!isAdmin && !updatedFoodstuff?.isCustomUnit && !newUnitCode)
        this.addCustomUnit();
      else if (!isAdmin && updatedFoodstuff?.isCustomUnit && newUnitCode)
        this.removeCustomUnit(0);
      newData.unitName = newUnitCode || value;
      newData.isCustomUnit = !newUnitCode;
    } else if (name.includes('customUnitName-')) {
      const [customUnitIndex] = name.split('-')[1];
      newCustomUnits[customUnitIndex].name = value;
      newData.customUnits = newCustomUnits;
    } else {
      newData[name] = value || null;
    }
    this.setState(prevState => ({
      changedData: {
        ...prevState.changedData,
        ...newData
      }
    }));
  };

  render() {
    const { handleFieldChanged, addCustomUnit, removeCustomUnit } = this;
    const {
      errors = [],
      data: initialFoodstuff,
      isAdmin,
      initialTitle,
      visibleSections,
      displayGramsPerMilliliter
    } = this.props;

    const updatedFoodstuff = {
      ...this.defaultFormData,
      ...(displayGramsPerMilliliter ? { unitName: '' } : {}),
      ...this.props.data,
      ...this.state.changedData
    };

    const showBasicInfo = visibleSections.includes(SECTIONS.BASIC_INFO);
    const showSystemSettings =
      isAdmin && visibleSections.includes(SECTIONS.SYSTEM_SETTINGS);
    const showUnit = visibleSections.includes(SECTIONS.UNIT);
    const showNutrient = visibleSections.includes(SECTIONS.NUTRIENT);

    return (
      <div className={s.foodstuffForm}>
        {(showBasicInfo || showUnit) && (
          <div className={s.mainProperties}>
            {showBasicInfo && (
              <BasicInfoFieldSet
                initialTitle={initialTitle}
                handleFieldChanged={handleFieldChanged}
                fieldSetStyle={s.propertiesGroup}
                updatedFoodstuff={updatedFoodstuff}
                errors={errors}
                isAdmin={isAdmin}
              />
            )}
            {showUnit && (
              <UnitFieldSet
                handleFieldChanged={handleFieldChanged}
                fieldSetStyle={s.propertiesGroup}
                updatedFoodstuff={updatedFoodstuff}
                addCustomUnit={addCustomUnit}
                removeCustomUnit={removeCustomUnit}
                errors={errors}
                showMultipleCustomUnits={isAdmin}
                displayGramsPerMilliliter={displayGramsPerMilliliter}
              />
            )}
          </div>
        )}
        {(showNutrient || showSystemSettings) && (
          <div className={s.nutrientProperties}>
            {showNutrient && (
              <NutrientFieldSet
                handleFieldChanged={handleFieldChanged}
                fieldSetStyle={s.propertiesGroup}
                updatedFoodstuff={updatedFoodstuff}
                errors={errors}
              />
            )}
            {showSystemSettings && (
              <SystemSettingsFieldSet
                handleFieldChanged={handleFieldChanged}
                fieldSetStyle={s.propertiesGroup}
                updatedFoodstuff={updatedFoodstuff}
                errors={errors}
                initialFoodstuff={initialFoodstuff}
                publiclyAvailabilityChanged={this.publiclyAvailabilityChanged}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

AdminFoodstuffFormFieldSets.propTypes = {
  onChange: PropTypes.func,
  isAdmin: PropTypes.bool,
  displayGramsPerMilliliter: PropTypes.bool,
  initialTitle: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    brandName: PropTypes.string,
    kcalPer100g: PropTypes.number,
    fatPercent: PropTypes.number,
    carbohydratePercent: PropTypes.number,
    proteinPercent: PropTypes.number,
    alcoholPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    unitName: PropTypes.string,
    gramsPerUnit: PropTypes.number,
    gramsPerMilliliter: PropTypes.number,
    foodType: PropTypes.string
  }),
  errors: PropTypes.array,
  visibleSections: PropTypes.array
};

export const SECTIONS = {
  BASIC_INFO: 'BASIC_INFO',
  UNIT: 'UNIT',
  NUTRIENT: 'NUTRIENT',
  SYSTEM_SETTINGS: 'SYSTEM_SETTINGS'
};

export default AdminFoodstuffFormFieldSets;
